import React, { useRef, useState, useEffect } from "react";
import get from "lodash/get";
import throttle from "lodash/throttle";
import { Link } from "@quintype/components";
import { useDispatch, useSelector } from "react-redux";

import { OPEN_SEARCHBAR, setNewNotification } from "../../../store/actions";
import { MenuItem } from "../../menu-item";
import Logo from "../../../atoms/icons/logo";
import HamburgerNotification from "../../../molecules/hamburger-notification";
import SocialShareIcons from "../../../molecules/social-share-header";
import MenuRight from "../../../atoms/icons/menu-right";
import HamburgerMenus from "../hamburger-menus";

import "./desktop-header.m.css";
import HeaderNotification from "../../../atoms/header-notification";

import HeaderRight from "../header-right";
import NotificationBox from "../../../molecules/notification-box";
import { addClickTrackingToDataLayer } from "../../../helper/datalayer-utils";

const DesktopHeader = () => {
  const getState = useSelector((state) => state);
  const dispatch = useDispatch();
  const primaryMenus = get(getState, ["qt", "data", "navigationMenu", "primaryMenus"], []);
  const secondaryMenus = get(getState, ["qt", "data", "navigationMenu", "secondaryMenus"], []);
  const notificationList = get(getState, "notificationList");
  const notificationBoxRef = useRef(null);

  const navRef = useRef();
  const [isSticky, setIsSticky] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
    console.log("CLicked");
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const handleScroll = throttle(() => {
    if (typeof window !== "undefined") {
      if (window.scrollY > 105) setIsSticky(true);
      if (window.scrollY < 106) setIsSticky(false);
    }
  }, 250);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (showNotifications) {
      window.localStorage.setItem("latestNotificationCreatedAt", notificationList[0]?.createdAt);
      dispatch(setNewNotification(false));
    }
  }, [showNotifications]);

  const notificationButtonHandler = () => {
    setShowNotifications(!showNotifications);
    dispatch(setNewNotification(false));
  };

  const handleClickOutside = (event) => {
    if (notificationBoxRef.current && !notificationBoxRef.current.contains(event.target)) {
      setShowNotifications(false);
    }
  };

  useEffect(() => {
    if (showNotifications) {
      document.body.style.overflow = "hidden";
      window.addEventListener("click", handleClickOutside);
    } else {
      document.body.style.overflow = "auto";
      window.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.body.style.overflow = "auto";
      window.removeEventListener("click", handleClickOutside);
    };
  }, [showNotifications]);

  const renderMenuItems = (items, type = "primary") => (
    <div styleName={`menu-wrapper ${type}-menu-wrapper ${isSticky ? "menu-wrapper-sticky" : ""}`}>
      {items.slice(0, type === "primary" ? 10 : 9).map((item, index) => (
        <div key={index} styleName={`${type}-menu-item`}>
          {type === "secondary" && <MenuRight />}
          <MenuItem
            type={type}
            changeTextColor={item?.data?.color.toLowerCase() !== "#ffffff"}
            changeTextWeight={item?.data?.color.toLowerCase() !== "#ffffff"}
            item={item}
            menuStyle="menu-link"
            toggleHandler={() => {
              dispatch({ type: OPEN_SEARCHBAR, isSearchBarOpen: false });
            }}
          />
          {item.children.length > 0 && (
            <>
              <div styleName="triangle"></div>
              <div styleName="ddsection">
                {Array.from({ length: Math.min(item.children.length, 32) }, (_, idx) => {
                  const subitem = item.children[idx];
                  return (
                    <div key={idx} styleName="subitem" onClick={() => addClickTrackingToDataLayer(subitem && subitem?.url, subitem.title, "header-l1")}>
                      <Link
                        externalLink={subitem?.isExternalLink}
                        href={subitem && subitem?.url}
                        style={{
                          color: `${subitem?.data?.color && subitem?.data?.color.toLowerCase() !== "#ffffff"
                            ? subitem?.data?.color
                            : ""
                            }`,
                          fontWeight: `${subitem?.data?.color.toLowerCase() !== "#ffffff" ? "bold" : ""}`
                        }}
                      >
                        {subitem?.title}
                      </Link>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );

  return (
    <>
      <div styleName="header">
        <div ref={navRef} styleName={`primary ${isSticky ? "extra-index" : ""}`} id="desk-header">
          <div className="container" styleName="primary-wrapper">
            <div styleName="logo-wrapper">
              <Link href="/">
                <Logo width="78" height="68" />
              </Link>
              {/* <div style={{ display: isSticky ? "flex" : "none" }}>
                <HamburgerNotification />
              </div> */}
            </div>
            {renderMenuItems(primaryMenus)}
            <div styleName="primary-right-wrapper">
              <HeaderRight />
            </div>
          </div>
        </div>

        <div styleName="secondary" style={{ display: !isSticky ? "block" : "none" }}>
          <div className="container" styleName="secondary-wrapper">
            {/* <div>
              <HamburgerNotification />
            </div> */}
            {renderMenuItems(secondaryMenus, "secondary")}
            <div styleName="button-wrapper">
              <SocialShareIcons />
              <div styleName="notification-wrapper">
                <div onClick={notificationButtonHandler}>
                  <HeaderNotification />
                </div>
                {showNotifications && (
                  <div ref={notificationBoxRef}>
                    <NotificationBox closeBtnHandler={notificationButtonHandler} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <HamburgerMenus /> */}
    </>
  );
};

export default DesktopHeader;
